<template>
    <div>
        <el-upload
      class="avatar-uploader"
      :action="domain"
      :show-file-list="false"
      :on-success="UploadFaceImg"
      :data="QiNiYunL"
      :before-upload="gettoken"
      multiple
    >
      <el-image v-if="img || url" :src="img ? img : url" alt="" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <input type="text" v-model="key">
    </div>
  </template>
  
  <script>
  export default {
    props: ["clear", "url"],
    data() {
      return {
        img: "",
        key:'',
        domain: "http://up-z0.qiniup.com/",
        upload_qiniu_addr: "http://img.curiousmore.com/",
        QiNiYunL: {
          key: "",
          token: "",
        },
      };
    },
    methods: {
      reset() {
        this.img = "";
      },
      UploadFaceImg(file) {
        this.img = this.upload_qiniu_addr + file.key;
        this.$emit("getImgUrl", this.img);
        console.log(this.img);
      },
      gettoken() {
        let _this = this;
        let data = {
          key: '1662532949934.png',
          type: "pic",
        };
        _this
          .$axios({
            method: "post",
            url: "https://www.curiousmore.com/qmore/push/upload/getToken",
            data: data,
          })
          .then((res) => {
            _this.QiNiYunL.token = res.data.data;
            _this.QiNiYunL.key = data.key;
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
    created() {
      this.gettoken();
    },
    watch: {
      clear(newV, oldV) {
        if (newV) {
          // console.log(newV);
          this.img = "";
        }
      },
    },
  };
  </script>
  
  <style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .el-upload-list__item,
  .el-upload-list--picture-card,
  .el-upload-list__item-actions,
  .el-upload--picture-card,
  .avatar-uploader-icon {
    font-size: 14px !important;
    color: #8c939d;
    width: 5.6rem !important;
    height: 5.6rem !important;
    line-height: 5.6rem !important;
    text-align: center;
  }
  .avatar {
    width: 5.6rem !important;
    height: 5.6rem !important;
    display: block;
    /* margin-right:0px; */
  }
  </style>
  